import React from 'react'
import { compose } from 'recompose'
import SEO from '../../../components/seo'
import { AuthUserContext, withAuthorization } from '../../../components/Session'
import { navigate } from 'gatsby'
import { NewAccount } from '../../../Views'

const AddAccount = () => {

    return (
    <>
        <SEO title="Accounts" />
        <AuthUserContext.Consumer>
            {authUser => (
                authUser.isAdmin ? <div>
                    <NewAccount path="/dashboard/accounts/new-account" authUser={authUser} />
                </div> : navigate('/404')
            )}
        </AuthUserContext.Consumer>
    </>
    )

}

const condition = authUser => !!authUser

export default compose(
    withAuthorization(condition),
)(AddAccount)
